var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("operation.inputActivityType"),
                clearable: ""
              },
              on: { change: _vm.change },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.types, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _vm.listQuery.type === 30
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "130px" },
                  attrs: {
                    placeholder: _vm.$t("operation.inputActivityType"),
                    clearable: ""
                  },
                  on: { change: _vm.change2 },
                  model: {
                    value: _vm.listQuery.battleType,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "battleType", $$v)
                    },
                    expression: "listQuery.battleType"
                  }
                },
                _vm._l(_vm.battleTypes, function(item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.name, value: item.key }
                  })
                }),
                1
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          ),
          [5, 19].indexOf(_vm.listQuery.type) >= 0
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-add" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.editItem()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("gameSetting.add")) + "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.dataObj && _vm.dataObj.rows && _vm.dataObj.rows.length > 0
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.dataObj && _vm.dataObj.rows, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: _vm.$t("operation.activityType"),
                  "min-width": "80"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.getProductType(scope.row.type)))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  213473850
                )
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: _vm.$t("default.operate"),
                  "min-width": "160"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          [5, 19].indexOf(_vm.listQuery.type) < 0 ||
                          scope.row.canedit
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editItem(scope.row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("default.edit")) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3318433694
                )
              }),
              [8, 9].indexOf(_vm.listQuery.type) < 0
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "startDate",
                          label: _vm.$t("statManager.startTime"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stopDate",
                          label: _vm.$t("statManager.endTime"),
                          "min-width": "120"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              [13, 14, 15, 17, 31].indexOf(_vm.listQuery.type) >= 0
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "isAlert",
                          label: _vm.$t("statManager.isAlert"),
                          "min-width": "120"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQuery.type === 8
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "uid",
                          label: _vm.$t("agents.playerId"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "minutes",
                          label: _vm.$t("operation.minutes"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "id",
                          label: _vm.$t("operation.id"),
                          "min-width": "120"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQuery.type === 9
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "money",
                          label: _vm.$t("default.diamond"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "coin",
                          label: _vm.$t("default.coin"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "items",
                          label: _vm.$t("user.other"),
                          "min-width": "120"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQuery.type === 1
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "openTime1",
                          label: _vm.$t("operation.openTime") + "1",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "closeTime1",
                          label: _vm.$t("operation.closeTime") + "1",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "openTime2",
                          label: _vm.$t("operation.openTime") + "2",
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "closeTime2",
                          label: _vm.$t("operation.closeTime") + "2",
                          "min-width": "120"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQuery.type === 5
                ? _c(
                    "div",
                    [
                      false
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "id",
                              label: "ID",
                              "min-width": "200"
                            }
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rankType",
                          label: _vm.$t("operation.rankType"),
                          "min-width": "120"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getRankType(scope.row.rankType)
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3665714609
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rewardDate",
                          label: _vm.$t("operation.rewardDate"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "itemId",
                          label: _vm.$t("operation.relationItemId"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "filter",
                          label: _vm.$t("operation.filter"),
                          "min-width": "120"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQuery.type === 19
                ? _c(
                    "div",
                    [
                      false
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "id",
                              label: "ID",
                              "min-width": "200"
                            }
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rankType",
                          label: _vm.$t("operation.rankType"),
                          "min-width": "120"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getRankType(scope.row.rankType)
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3665714609
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "itemId",
                          label: _vm.$t("operation.relationItemId"),
                          "min-width": "120"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQuery.type === 6
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "openDate",
                          label: _vm.$t("operation.openDate"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "closeDate",
                          label: _vm.$t("operation.closeDate"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: _vm.$t("operation.title"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "context",
                          label: _vm.$t("operation.context"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "isTimeLimit",
                          label: _vm.$t("operation.isTimeLimit"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "imagePath",
                          label: _vm.$t("operation.imagePath"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "isImgCanTouch",
                          label: _vm.$t("operation.isImgCanTouch"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "btnType",
                          label: _vm.$t("operation.btnType"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "weight",
                          label: _vm.$t("operation.weight"),
                          "min-width": "120"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQuery.type === 7
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "level1",
                          label: _vm.$t("operation.level1"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "coin1",
                          label: _vm.$t("operation.coin1"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "money1",
                          label: _vm.$t("operation.money1"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "item1",
                          label: _vm.$t("operation.item1"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "days1",
                          label: _vm.$t("operation.days1"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "count1",
                          label: _vm.$t("operation.count1"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "level2",
                          label: _vm.$t("operation.level2"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "coin2",
                          label: _vm.$t("operation.coin2"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "money2",
                          label: _vm.$t("operation.money2"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "item2",
                          label: _vm.$t("operation.item2"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "days2",
                          label: _vm.$t("operation.days2"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "count2",
                          label: _vm.$t("operation.count2"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "level3",
                          label: _vm.$t("operation.level3"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "coin3",
                          label: _vm.$t("operation.coin3"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "money3",
                          label: _vm.$t("operation.money3"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "item3",
                          label: _vm.$t("operation.item3"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "days3",
                          label: _vm.$t("operation.days3"),
                          "min-width": "120"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "count3",
                          label: _vm.$t("operation.count3"),
                          "min-width": "120"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 30
        ? _c(
            "div",
            [
              _vm.formData.data
                ? _c(
                    "el-form",
                    {
                      ref: "formData",
                      staticClass: "activity-form",
                      attrs: { model: _vm.formData, "label-width": "120px" }
                    },
                    [
                      _vm._l(_vm.formData.data, function(item) {
                        return _c(
                          "el-form-item",
                          {
                            key: item.k,
                            attrs: {
                              label: item.k,
                              value: item.v,
                              "label-width": "120px"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: item.v,
                                callback: function($$v) {
                                  _vm.$set(item, "v", $$v)
                                },
                                expression: "item.v"
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _c("el-form-item", [
                        _c(
                          "button",
                          {
                            staticClass: "el-button el-button--primary",
                            attrs: { type: "submit" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.saveOrUpdate("formData")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("default.save")) +
                                "\n        "
                            )
                          ]
                        )
                      ])
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("operation.addAndEdit"),
            visible: _vm.dialogEditorVisible,
            "modal-append-to-body": false,
            "before-close": _vm.onDialogClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEditorVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "activity-form",
              attrs: { model: _vm.formData, "label-width": "120px" }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    prop: "activityType",
                    label: _vm.$t("operation.activityType")
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: true },
                    model: {
                      value: _vm.formData.activityType,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "activityType", $$v)
                      },
                      expression: "formData.activityType"
                    }
                  })
                ],
                1
              ),
              [8, 9].indexOf(_vm.listQuery.type) < 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: {
                            "label-width": "120px",
                            label: _vm.$t("statManager.startTime")
                          }
                        },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              type: "datetime",
                              placeholder: _vm.$t("operation.selectDatetime"),
                              "value-format": "yyyy-MM-dd HH:mm",
                              format: "yyyy-MM-dd HH:mm",
                              align: "right",
                              "unlink-panels": ""
                            },
                            model: {
                              value: _vm.formData.startDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "startDate", $$v)
                              },
                              expression: "formData.startDate"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.listQuery.type !== 10
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              attrs: {
                                "label-width": "120px",
                                label: _vm.$t("statManager.endTime")
                              }
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "filter-item",
                                staticStyle: { width: "300px" },
                                attrs: {
                                  type: "datetime",
                                  placeholder: _vm.$t(
                                    "operation.selectDatetime"
                                  ),
                                  "value-format": "yyyy-MM-dd HH:mm",
                                  format: "yyyy-MM-dd HH:mm",
                                  align: "right",
                                  "unlink-panels": ""
                                },
                                on: { change: _vm.changeDate },
                                model: {
                                  value: _vm.formData.stopDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "stopDate", $$v)
                                  },
                                  expression: "formData.stopDate"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      [13, 14, 15, 17, 31].indexOf(_vm.listQuery.type) >= 0
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "isAlert",
                                label: _vm.$t("statManager.isAlert")
                              }
                            },
                            [
                              _c("el-checkbox", {
                                model: {
                                  value: _vm.formData.isAlert,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "isAlert", $$v)
                                  },
                                  expression: "formData.isAlert"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQuery.type === 6
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: {
                            "label-width": "120px",
                            label: _vm.$t("operation.openDate")
                          }
                        },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              type: "datetime",
                              placeholder: _vm.$t("operation.selectDatetime"),
                              "value-format": "yyyy-MM-dd HH:mm",
                              format: "yyyy-MM-dd HH:mm",
                              align: "right",
                              "unlink-panels": ""
                            },
                            model: {
                              value: _vm.formData.openDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "openDate", $$v)
                              },
                              expression: "formData.openDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: {
                            "label-width": "120px",
                            label: _vm.$t("operation.closeDate")
                          }
                        },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              type: "datetime",
                              placeholder: _vm.$t("operation.selectDatetime"),
                              "value-format": "yyyy-MM-dd HH:mm",
                              format: "yyyy-MM-dd HH:mm",
                              align: "right",
                              "unlink-panels": ""
                            },
                            on: { change: _vm.changeDate },
                            model: {
                              value: _vm.formData.closeDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "closeDate", $$v)
                              },
                              expression: "formData.closeDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.title"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.title,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "title", $$v)
                            },
                            expression: "formData.title"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.title,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "title", $$v)
                              },
                              expression: "formData.title"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.context"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.context,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "context", $$v)
                            },
                            expression: "formData.context"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.formData.context,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "context", $$v)
                              },
                              expression: "formData.context"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.isTimeLimit"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.isTimeLimit,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "isTimeLimit", $$v)
                            },
                            expression: "formData.isTimeLimit"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.isTimeLimit,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "isTimeLimit", $$v)
                              },
                              expression: "formData.isTimeLimit"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.imagePath"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.imagePath,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "imagePath", $$v)
                            },
                            expression: "formData.imagePath"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.imagePath,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "imagePath", $$v)
                              },
                              expression: "formData.imagePath"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.isImgCanTouch"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.isImgCanTouch,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "isImgCanTouch", $$v)
                            },
                            expression: "formData.isImgCanTouch"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.isImgCanTouch,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "isImgCanTouch", $$v)
                              },
                              expression: "formData.isImgCanTouch"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.btnType"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.btnType,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "btnType", $$v)
                            },
                            expression: "formData.btnType"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.btnType,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "btnType", $$v)
                              },
                              expression: "formData.btnType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.weight"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.weight,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "weight", $$v)
                            },
                            expression: "formData.weight"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.weight,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "weight", $$v)
                              },
                              expression: "formData.weight"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.listQuery.type === 7
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.level1"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.level1,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "level1", $$v)
                            },
                            expression: "formData.level1"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.level1,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "level1", $$v)
                              },
                              expression: "formData.level1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.coin1"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.coin1,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "coin1", $$v)
                            },
                            expression: "formData.coin1"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.coin1,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "coin1", $$v)
                              },
                              expression: "formData.coin1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.money1"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.money1,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "money1", $$v)
                            },
                            expression: "formData.money1"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.money1,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "money1", $$v)
                              },
                              expression: "formData.money1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.item1"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.item1,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "item1", $$v)
                            },
                            expression: "formData.item1"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.item1,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "item1", $$v)
                              },
                              expression: "formData.item1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.days1"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.days1,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "days1", $$v)
                            },
                            expression: "formData.days1"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.days1,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "days1", $$v)
                              },
                              expression: "formData.days1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.count1"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.count1,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "count1", $$v)
                            },
                            expression: "formData.count1"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.count1,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "count1", $$v)
                              },
                              expression: "formData.count1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.level2"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.level2,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "level2", $$v)
                            },
                            expression: "formData.level2"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.level2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "level2", $$v)
                              },
                              expression: "formData.level2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.coin2"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.coin2,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "coin2", $$v)
                            },
                            expression: "formData.coin2"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.coin2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "coin2", $$v)
                              },
                              expression: "formData.coin2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.money2"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.money2,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "money2", $$v)
                            },
                            expression: "formData.money2"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.money2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "money2", $$v)
                              },
                              expression: "formData.money2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.item2"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.item2,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "item2", $$v)
                            },
                            expression: "formData.item2"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.item2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "item2", $$v)
                              },
                              expression: "formData.item2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.days2"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.days2,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "days2", $$v)
                            },
                            expression: "formData.days2"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.days2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "days2", $$v)
                              },
                              expression: "formData.days2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.count2"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.count2,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "count2", $$v)
                            },
                            expression: "formData.count2"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.count2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "count2", $$v)
                              },
                              expression: "formData.count2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.level3"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.level3,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "level3", $$v)
                            },
                            expression: "formData.level3"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.level3,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "level3", $$v)
                              },
                              expression: "formData.level3"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.coin3"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.coin3,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "coin3", $$v)
                            },
                            expression: "formData.coin3"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.coin3,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "coin3", $$v)
                              },
                              expression: "formData.coin3"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.money3"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.money3,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "money3", $$v)
                            },
                            expression: "formData.money3"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.money3,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "money3", $$v)
                              },
                              expression: "formData.money3"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.item3"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.item3,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "item3", $$v)
                            },
                            expression: "formData.item3"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.item3,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "item3", $$v)
                              },
                              expression: "formData.item3"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.days3"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.days3,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "days3", $$v)
                            },
                            expression: "formData.days3"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.days3,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "days3", $$v)
                              },
                              expression: "formData.days3"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.count3"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.count3,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "count3", $$v)
                            },
                            expression: "formData.count3"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.count3,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "count3", $$v)
                              },
                              expression: "formData.count3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.type === 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: {
                            "label-width": "120px",
                            label: _vm.$t("operation.openTime") + "1"
                          }
                        },
                        [
                          _c("el-time-picker", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: _vm.$t("operation.selectDatetime"),
                              format: "HH",
                              align: "right",
                              "unlink-panels": ""
                            },
                            model: {
                              value: _vm.formData.openTime1,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "openTime1", $$v)
                              },
                              expression: "formData.openTime1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: {
                            "label-width": "120px",
                            label: _vm.$t("operation.closeTime") + "1"
                          }
                        },
                        [
                          _c("el-time-picker", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: _vm.$t("operation.selectDatetime"),
                              format: "HH",
                              align: "right",
                              "unlink-panels": ""
                            },
                            model: {
                              value: _vm.formData.closeTime1,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "closeTime1", $$v)
                              },
                              expression: "formData.closeTime1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: {
                            "label-width": "120px",
                            label: _vm.$t("operation.openTime") + "2"
                          }
                        },
                        [
                          _c("el-time-picker", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: _vm.$t("operation.selectDatetime"),
                              format: "HH",
                              align: "right",
                              "unlink-panels": ""
                            },
                            model: {
                              value: _vm.formData.openTime2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "openTime2", $$v)
                              },
                              expression: "formData.openTime2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "postInfo-container-item",
                          attrs: {
                            "label-width": "120px",
                            label: _vm.$t("operation.closeTime") + "2"
                          }
                        },
                        [
                          _c("el-time-picker", {
                            staticClass: "filter-item",
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: _vm.$t("operation.selectDatetime"),
                              format: "HH",
                              align: "right",
                              "unlink-panels": ""
                            },
                            model: {
                              value: _vm.formData.closeTime2,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "closeTime2", $$v)
                              },
                              expression: "formData.closeTime2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.type === 5
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.rankType"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.rankType,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "rankType", $$v)
                            },
                            expression: "formData.rankType"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t("operation.selectRankType")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.selectTrigger()
                                }
                              },
                              model: {
                                value: _vm.formData.rankType,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "rankType", $$v)
                                },
                                expression: "formData.rankType"
                              }
                            },
                            _vm._l(_vm.rankTypes || [], function(item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: { label: item.name, value: item.key }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.rewardDate"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.rewardDate,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "rewardDate", $$v)
                            },
                            expression: "formData.rewardDate"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text", readonly: true },
                            model: {
                              value: _vm.formData.rewardDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "rewardDate", $$v)
                              },
                              expression: "formData.rewardDate"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.showRelationItemId
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("operation.relationItemId"),
                                "min-width": "120"
                              },
                              model: {
                                value: _vm.formData.itemId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "itemId", $$v)
                                },
                                expression: "formData.itemId"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.formData.itemId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "itemId", $$v)
                                  },
                                  expression: "formData.itemId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showfilter
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("operation.filter"),
                                "min-width": "120"
                              },
                              model: {
                                value: _vm.formData.filter,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "filter", $$v)
                                },
                                expression: "formData.filter"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.formData.filter,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "filter", $$v)
                                  },
                                  expression: "formData.filter"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.type === 19
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.rankActivity27"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.itemId,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "itemId", $$v)
                            },
                            expression: "formData.itemId"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.itemId27,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "itemId27", $$v)
                              },
                              expression: "formData.itemId27"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.rankActivity28"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.itemId,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "itemId", $$v)
                            },
                            expression: "formData.itemId"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.itemId28,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "itemId28", $$v)
                              },
                              expression: "formData.itemId28"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.rankActivity29"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.itemId,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "itemId", $$v)
                            },
                            expression: "formData.itemId"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.itemId29,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "itemId29", $$v)
                              },
                              expression: "formData.itemId29"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.type === 8
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("agents.playerId"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.uid,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "uid", $$v)
                            },
                            expression: "formData.uid"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.uid,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "uid", $$v)
                              },
                              expression: "formData.uid"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.minutes"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.minutes,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "minutes", $$v)
                            },
                            expression: "formData.minutes"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.minutes,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "minutes", $$v)
                              },
                              expression: "formData.minutes"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("operation.id"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.id,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "id", $$v)
                            },
                            expression: "formData.id"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text", readonly: true },
                            model: {
                              value: _vm.formData.id,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "id", $$v)
                              },
                              expression: "formData.id"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.type === 9
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("default.diamond"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.money,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "money", $$v)
                            },
                            expression: "formData.money"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.money,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "money", $$v)
                              },
                              expression: "formData.money"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("default.coin"),
                            "min-width": "120"
                          },
                          model: {
                            value: _vm.formData.coin,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "coin", $$v)
                            },
                            expression: "formData.coin"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.formData.coin,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "coin", $$v)
                              },
                              expression: "formData.coin"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._l(_vm.select, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "itemType",
                                  label:
                                    _vm.$t("gameSetting.itemType") + (index + 1)
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "gameSetting.selectItemType"
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.selectTrigger2(index)
                                      }
                                    },
                                    model: {
                                      value: _vm.select[index].value,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.select[index],
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "select[index].value"
                                    }
                                  },
                                  _vm._l(_vm.itemTypes || [], function(
                                    item2,
                                    key
                                  ) {
                                    return _c("el-option", {
                                      key: key,
                                      attrs: {
                                        label: _vm.$t(item2.dec),
                                        value: item2.value
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteSelect(index)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-minus" })]
                                )
                              ],
                              1
                            ),
                            _vm.formData.items &&
                            _vm.formData.items[index] &&
                            item.value === 3
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.$t("gameSetting.itemId") +
                                        (index + 1)
                                    },
                                    model: {
                                      value: _vm.formData.items[index].itemId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.items[index],
                                          "itemId",
                                          $$v
                                        )
                                      },
                                      expression: "formData.items[index].itemId"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputItemType"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value: _vm.formData.items[index].itemId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "itemId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.items[index].itemId"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formData.items &&
                            _vm.formData.items[index] &&
                            item.value === 3
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.$t("gameSetting.itemCount") +
                                        (index + 1)
                                    },
                                    model: {
                                      value: _vm.formData.items[index].count,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.items[index],
                                          "count",
                                          $$v
                                        )
                                      },
                                      expression: "formData.items[index].count"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputItemCount"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value: _vm.formData.items[index].count,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "count",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.items[index].count"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formData.items &&
                            _vm.formData.items[index] &&
                            item.value === 3
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.$t("gameSetting.timeRange") +
                                        (index + 1)
                                    },
                                    model: {
                                      value: _vm.formData.items[index].days,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.items[index],
                                          "days",
                                          $$v
                                        )
                                      },
                                      expression: "formData.items[index].days"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputItemTimeRange"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value: _vm.formData.items[index].days,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "days",
                                            $$v
                                          )
                                        },
                                        expression: "formData.items[index].days"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addSelect }
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _vm.formData.type === 11
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.select, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _vm.formData.items && _vm.formData.items[index]
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "gameSetting.activityConfig"
                                      )
                                    },
                                    model: {
                                      value: _vm.formData.items[index].id1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.items[index],
                                          "id1",
                                          $$v
                                        )
                                      },
                                      expression: "formData.items[index].id1"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "text" },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value: _vm.select[index].value,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.select[index],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "select[index].value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formData.items && _vm.formData.items[index]
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("gameSetting.activity1")
                                    },
                                    model: {
                                      value: _vm.formData.items[index].id1,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.items[index],
                                          "id1",
                                          $$v
                                        )
                                      },
                                      expression: "formData.items[index].id1"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputactivity1"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value: _vm.formData.items[index].id1,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "id1",
                                            $$v
                                          )
                                        },
                                        expression: "formData.items[index].id1"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formData.items && _vm.formData.items[index]
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("gameSetting.activity2")
                                    },
                                    model: {
                                      value: _vm.formData.items[index].id2,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.items[index],
                                          "id2",
                                          $$v
                                        )
                                      },
                                      expression: "formData.items[index].id2"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputactivity2"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value: _vm.formData.items[index].id2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "id2",
                                            $$v
                                          )
                                        },
                                        expression: "formData.items[index].id2"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formData.items && _vm.formData.items[index]
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("gameSetting.activity3")
                                    },
                                    model: {
                                      value: _vm.formData.items[index].id3,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.items[index],
                                          "id3",
                                          $$v
                                        )
                                      },
                                      expression: "formData.items[index].id3"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t(
                                          "gameSetting.inputactivity3"
                                        )
                                      },
                                      on: { input: _vm.forceUpdate },
                                      model: {
                                        value: _vm.formData.items[index].id3,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData.items[index],
                                            "id3",
                                            $$v
                                          )
                                        },
                                        expression: "formData.items[index].id3"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addSelect }
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _c("el-form-item", [
                _c(
                  "button",
                  {
                    staticClass: "el-button el-button--primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.saveOrUpdate("formData")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("default.save")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }